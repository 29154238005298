<template>
  <div class="detection_history">
<!--    <NavBar title="检测历史" class="nav_bottom"></NavBar>-->
    <main>
      <van-list v-if="!emptyFlag"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item" v-for="v in historyArray" :key=v.id>
          <van-swipe-cell>
            <p>{{ v.year+"年"+v.member+"检测报告" }}</p>
            <template #right>
              <div class="btns">
                <div class="edit" @click="edit(v)">修改</div>
                <div class="edit see" @click="see(v)">查看</div>
              </div>
            </template>
          </van-swipe-cell>
        </div>
      </van-list>

<!--      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item" :title="item" />
      </van-list>-->
      <el-empty v-if="emptyFlag" description="未检测过影院"  image="https://img01.yzcdn.cn/vant/empty-image-default.png"></el-empty>
    </main>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import {SwipeCell,List} from "vant";
import {GetTestingHistory} from "../axios/api";
import { Loading,Empty } from 'element-ui';

export default {
  name: "DetectionHistory",
  components:{
    NavBar,
    [SwipeCell.name]:SwipeCell,
    [Empty.name]:Empty,
    [List.name]:List
  },
  data(){
    return{
      historyArray:[],
      emptyFlag:false,
      page:1,
      loading: false,
      finished: false,
    }
  },
  created() {
    // let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
    // GetTestingHistory({token:localStorage.getItem("token")}).then(respose=>{
    //   if(respose.state==="1"){
    //     this.historyArray=respose.data;
    //     this.historyArray.reverse();
    //   }else if(respose.state==="0"){
    //     this.emptyFlag=true
    //   }
    //   loadingInstance.close();
    // }).catch(error=>{
    //   console.log(error);
    // })
  },
  methods:{
    edit(v){
      localStorage.setItem("did",v.id);
      this.$router.push({name:"SelectionHall",params:{id:v.mid}})
    },
    see(v){
      localStorage.setItem("did",v.id);
      this.$router.push({name:"SelectionHallsee",params:{id:v.mid,see:'y'}})
    },
    onLoad() {
      GetTestingHistory({token:localStorage.getItem("token"),page:this.page}).then(respose=>{
        //如果第一次请求 并且返回为0那么就是空内容
        if(this.page==1 && respose.state==0){
          this.emptyFlag=true
        }
        this.page=this.page+1;
        if(respose.state==="1"){
          console.log(respose.data)
          this.historyArray.push(...respose.data);
        }
        // 加载状态结束
        this.loading = false;

        if(respose.data.length<5){
          this.finished = true;
        }
      }).catch(error=>{
        console.log(error);
      })


    },
  }
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 35px*/
/*}*/
.detection_history{
  padding: 24px 0 33px;
}
main {
  min-height: calc(100vh);
  overflow: hidden;
}
.item {
  margin: 22px auto 0;
  width: 690px;
  height: 150px;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 5px rgba(112, 112, 112, 0.06);
  border-radius: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
>>>.van-swipe-cell,>>>.van-swipe-cell__wrapper{
  width: 690px;
  height: 150px;
  display: flex;
  align-items: center;
}
.item p {
  /*margin: 51px 243px 51px 44px;
    width: 502px;*/
  margin: 0 0 0 44px;
  width: 651px;

  font-size: 34px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 40px;
}
.item .btns {
  width: 230px;
  height: 124px;
  margin-right: 13px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
  text-align: center;
  line-height: 124px;
  cursor: pointer;
}
.item .btns .edit {
  float: left;
  width: 115px;
  height: 124px;
  background: #FFB444;
  border-radius: 3px 0px 0px 3px;
}
.item .btns .see {
  background: #577BFF;
}
.detection_history>>>.van-swipe-cell__right {
  margin-top: 13px;
}

</style>
